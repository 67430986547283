<template>
    <div class="opportunity-calendar-sidebar-container flex-column">
        <p class="opportunity-calendar-sidebar-container__header default-text">Upcoming courses</p>
        <div class="opportunity-calendar-sidebar-container__summary-box flex-column js-fs" v-show="data !== null">
           <p class="opportunity-calendar-sidebar-container__title default-text">{{ data?.title }}</p>
           <p class="opportunity-calendar-sidebar-container__date default-text">Starting date: {{ startDate }}</p>
           <p class="opportunity-calendar-sidebar-container__spots default-text">Spots available: {{ data?.maxLimit }} </p>
        </div>
        <div class="opportunity-calendar-sidebar-container__checkbox-container flex-column">
           <el-checkbox v-model="addToLiveQueue" :disabled="data === null">
                <p class="opportunity-calendar-sidebar-container__terms default-text flex-row">
                    Add me to the live queue.
                    <span><el-icon :size="15" color="rgba(12, 15, 74, 0.3)"><InfoFilled/></el-icon></span>
                </p>
            </el-checkbox>
            <el-checkbox v-model="agreedToTerms" :disabled="data === null">
                <p class="opportunity-calendar-sidebar-container__terms default-text">
                    I have read and agree with the
                    <span class="yellow">Terms of<br> Service & Privacy Policy</span>
                </p>
            </el-checkbox>
        </div>
        <el-button type="primary" class="opportunity-calendar-sidebar-container__button default-text flex-row ai-c jc-c" :class="{'active': agreedToTerms}" :disabled="!agreedToTerms" round :loading="submitting" @click="openConfirmModal">
            confirm
        </el-button>
    </div>
</template>
<script>
import { InfoFilled } from '@element-plus/icons';

import appFilters from '@/filters';

export default {
  components: {
    InfoFilled,
  },
  props: ['data'],
  emits: ['open-confirm-modal'],
  data() {
    return {
      agreedToTerms: false,
      addToLiveQueue: false
    };
  },
  computed: {
    startDate() {
      let value = '';

      if (this.data?.startDate) {
        value = appFilters.formatToDate(this.data.startDate, 'Do MMM YYYY');
      }
      return value;
    }
  },
  methods: {
    openConfirmModal() {
      this.$emit('open-confirm-modal');
    }
  },
};
</script>
<style lang="scss" scoped>
.opportunity-calendar-sidebar-container {
    &__header {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
    }
    &__summary-box {
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(42, 42, 42, 0.1);
        border-radius: 8px;
        padding: 1rem;
        gap: .7rem;
    }
    &__title {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.25px;
    }
    &__date {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.25px;
        color: #FFA500;
    }
    &__spots {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.25px;
    }
    &__terms {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.4000000059604645px;
        text-align: left;
        color: #9B9FB8;
        gap: .7rem;

        .yellow {
            color: #FFA500;
        }
    }
    &__checkbox-container {
        .el-checkbox {
            align-items: flex-start;
            height: auto;

            :deep(.el-checkbox__input .el-checkbox__inner) {
                border-radius: 100px;
                background: rgba(12, 15, 74, 0.05);
                border: 0 solid transparent;
                width: 18px;
                height: 18px;
            }

            :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
                display: none;
            }

            :deep(.el-checkbox__input.is-focus .el-checkbox__inner) {
                border: 0 solid transparent;
                background: rgba(12, 15, 74, 0.05);
                transition: 0.2s ease-in-out;
            }

            :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
                background: #ffa500;
                border: 4px solid #ffa500;
                transition: 0.2s ease-in-out;
            }
        }
    }
    &__button {
        height: 40px;
        padding: 0 2rem;
        box-sizing: border-box;
        background-color: rgba(12, 15, 74, 0.05);
        border: none;
        border-radius: 7px;
        font-size: 15px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 1.25px;
        color: rgba(12, 15, 74, 0.5);
        width: 100%;
        text-transform: uppercase;
        font-family: Mulish;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 1.25px;

        &:hover {
            background-color: rgba(12, 15, 74, 0.05);
            color: rgba(12, 15, 74, 0.5);
        }
    }
}
.active {
    background-color: #FFA500;
    color: #fff;

    &:hover {
        background-color: #FFA500;
        color: #fff;
    }
}
</style>
