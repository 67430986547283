<template>
    <DefaultGridLayoutSlot v-if="hasMainLayoutInitialized" class="opportunity-calendar-page">
        <CalendarComponent :attributes="attributes" @set-active-object="setActiveObject"/>
        <CalendarRightSidbar :data="activeObject" @open-confirm-modal="isOpenSpotConfirmationModal = true"/>

      <!-- MODAL -->
      <SpotConfirmationModal :data="activeObject" :show="isOpenSpotConfirmationModal" @close="onClose" @confirm="onConfirmation"/>
    </DefaultGridLayoutSlot>
</template>
<script>
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import CalendarComponent from '@/core/components/common/calendar/CalendarComponent.vue';
import DefaultGridLayoutSlot from '@/core/components/slots/DefaultGridLayoutSlot.vue';
import $axios from '@/core/utils/axios-api-config';
import CalendarRightSidbar from '@/modules/opportunity-calendar/components/CalendarRightSidebar.vue';
import SpotConfirmationModal from '@/modules/opportunity-calendar/components/modal/SpotConfirmationModal.vue';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { OPPORTUNITY_STORE } from '@/store/modules/opportunity';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    CalendarRightSidbar,
    CalendarComponent,
    SpotConfirmationModal,
    DefaultGridLayoutSlot
  },
  data() {
    // const month = new Date().getMonth();
    // const year = new Date().getFullYear();
    return {
      isOpenSpotConfirmationModal: false,
      activeObject: null,
      attributes: [
        // {
        //   key: 1,
        //   customData: {
        //     title: 'Lunch with mom.',
        //     class: 'bg-red-600 text-white',
        //   },
        //   dates: new Date(year, month, 1),
        // }
      ],
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized', 'user']),

    ...mapGetters(OPPORTUNITY_STORE, ['currentCourse']),

    ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
  },

  async created() {
    await this.checkSuccessfulTransaction();

    // if (!this.currentCourse) {
    const { params } = this.$route;
    await this.loadCourse(params.courseId);
    // }

    await this.loadCourseSchedules();
  },

  methods: {
    ...mapActions(OPPORTUNITY_STORE, [
      'setCourses',
      'setCurrentCourse',
      'getCourse',
      'createUserCourseSchedule'
    ]),
    ...mapActions(['setPaymentSuccessfulModal']),

    routeToCourses() {
      this.$router.push({ name: 'opportunity/courses' });
    },

    routeToDetails() {
      this.$router.push({ name: 'opportunity-details' });
    },

    async onClose() {
      this.isOpenSpotConfirmationModal = false;
    },

    async onConfirmation() {
      await this.saveUserCourseSchedule();
    },

    syncCourses() {
      const updatedCourse = {
        ...this.currentCourse,
        hasSelectedDate: this.activeObject.id, // course schedule id,
        selectedStartDate: this.activeObject.startDate
      };
      this.setCurrentCourse(updatedCourse);
      this.setCourses(updatedCourse);
    },

    async saveUserCourseSchedule() {
      const { userCourses } = this.currentCourse;
      const myUserCourse = userCourses.find((userCourse) => userCourse.user.id === this.user.id);

      if (myUserCourse) {
        const data = {
          userCourseId: myUserCourse.id,
          formData: {
            courseScheduleId: this.activeObject.id
          }
        };

        await this.createUserCourseSchedule(data).then(() => {
          this.$router.push({ name: 'courses-enrolled' });
        }).catch((e) => {
          this.$notify.error({
            message: e || 'Error occured, please try again'
          });
        });
      } else {
        this.$notify.error({
          message: 'Unable to select a date, please try again.'
        });
      }
    },

    async loadCourse(courseId) {
      await this.getCourse(courseId).then((course) => {
        if (course) {
          this.setCurrentCourse(course);
        }
      });
    },

    loadCourseSchedules() {
      const { currentCourse } = this;

      if (currentCourse) {
        const { name, courseSchedules } = currentCourse;
        const schedules = courseSchedules.map((courseSchedule) => {
          const { id, startDate, maxLimit } = courseSchedule;

          return {
            key: id,
            customData: {
              id,
              title: name,
              class: 'bg-red-600 text-white',
              maxLimit,
              startDate
            },
            dates: startDate
          };
        });

        this.attributes = schedules.filter((schedule) => {
          const d = new Date(schedule.customData.startDate);
          const scheduleDate = moment(d);

          return moment(scheduleDate).isAfter();
        });
      }
    },

    setActiveObject(object) {
      this.activeObject = object.customData;
    },

    async checkSuccessfulTransaction() {
      if (!this.currentCourse) {
        return;
      }

      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const { userId } = this.authenticatedUser;

      if (params.session_id) {
        const url = `/payment/${userId}/session?sessionId=${params.session_id}&isCourse=1`;

        await $axios.get(url)
          .then(async (sessionResponse) => {
            if (sessionResponse) {
              this.setPaymentSuccessfulModal(true);
            }
          });
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.opportunity-calendar-page {
  // max-height: unset !important;
  // height: 100% !important;
  // max-width: unset !important;
}
</style>
