<template>
<BaseModal
  :showCancelBtn="false"
  :showSubmitBtn="false"
  :showDefaultCloseBtn="false"
  @cancel="cancel"
  :hasActions="false"
  customClass="xs-modal"
>
  <div class="calendar-confirm-modal flex-column jc-sb">
    <div class="flex-column jc-sb">
        <div class="calendar-confirm-modal__modal-header flex-row jc-fe ai-c">
            <!-- <h2 class="modal-header__title default-text">Current plan</h2> -->
            <!-- <div class="calendar-confirm-modal__header-icon">
              <inline-svg @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
            </div> -->
        </div>
        <div class="calendar-confirm-modal__content flex-column jc-c ai-c">
          <inline-svg style="margin-bottom: 1rem;" :src="require(`@/assets/icons/filled-calendar-icon.svg`)"></inline-svg>
          <p class="calendar-confirm-modal__title default-text flex-column ai-c jc-c">Please confirm your<br> selected date</p>
          <p class="calendar-confirm-modal__text-description default-text">Starting date: {{ startDate }}</p>
        </div>
    </div>
    <div class="calendar-confirm-modal__actions grid">
      <el-button @click="confirm" class="button active">CONFIRM</el-button>
      <el-button @click="cancel" class="button">CANCEL</el-button>
    </div>
  </div>
</BaseModal>
</template>
<script>
import { defineComponent } from '@vue/runtime-core';

import BaseModal from '@/core/components/ui/BaseModal.vue';
import appFilters from '@/filters';

export default defineComponent({
  name: 'spot-confirmation-modal',
  emits: ['close', 'confirm'],
  props: ['data'],
  components: {
    BaseModal,
  },

  computed: {
    startDate() {
      let value = '';

      if (this.data?.startDate) {
        value = appFilters.formatToDate(this.data.startDate, 'Do MMM YYYY');
      }
      return value;
    }
  },

  methods: {
    cancel() {
      this.$emit('close');
    },
    confirm() {
      this.$emit('confirm');
    },
  }
});
</script>
<style lang="scss" scoped>
.default-text {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.15000000596046448px;
}
.calendar-confirm-modal {
  &__content {
    width: 100%;
  }
  &__title {
    font-size: 24px;
    text-align: center;
  }
  &__text-description {
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: rgba(12, 15, 74, 0.5);
  }
  &__actions {
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }
}
.button {
    height: 40px;
    padding: 0 2rem;
    box-sizing: border-box;
    background-color: rgba(12, 15, 74, 0.05);
    border: none;
    border-radius: 7px;
    @extend .default-text;
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    color: rgba(12, 15, 74, 0.5);

    &:hover {
        background-color: rgba(12, 15, 74, 0.05);
        color: rgba(12, 15, 74, 0.5);
    }
}
.active {
    background-color: #FFA500;
    color: #fff;

    &:hover {
        background-color: #FFA500;
        color: #fff;
    }
}
</style>
