<template>
  <div class="text-center section">
    <vc-calendar
      class="custom-calendar max-w-full"
      :masks="masks"
      :attributes="attributes"
      disable-page-swipe
      is-expanded
      title-position="left"
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col h-full z-10 overflow-hidden">
          <span class="day-label text-sm text-gray-900 default-text">{{ day.day }}</span>
          <el-popover placement="bottom-start" width="auto" trigger="click" v-for="attr in attributes" :key="attr.key">
            <div class="pop-over flex-column">
                <div class="pop-over__option flex-row ai-fs" @click="openEditModal(attr?.customData)">
                  <inline-svg width="24" height="24" :src="require('@/assets/icons/upload-icon.svg')"/>
                  <p class="pop-over__label">Edit</p>
                </div>
                <div class="pop-over__option flex-row ai-fs" @click="openConfirmDeleteModal(attr?.customData?.id)">
                  <inline-svg width="24" height="24" :src="require('@/assets/icons/upload-icon.svg')"/>
                  <p class="pop-over__label">Delete</p>
                </div>
            </div>
            <template #reference>
              <div class="flex flex-col h-full z-10 overflow-hidden">
                  <div
                    class="event-info-container flex-grow overflow-y-auto overflow-x-auto"
                    :class="{'active': activeKey === attr.key}"
                    @click="setActiveObject(attr)">
                    <p
                      class="event-title text-xs leading-tight rounded-sm default-text"
                      :class="attr.customData.class"
                    >
                      {{ attr.customData.title }}
                    </p>
                    <p class="spots default-text" v-if="attr.customData.maxLimit">{{ attr.customData.maxLimit }}spot(s) available</p>
                  </div>
                </div>
            </template>
          </el-popover>
        </div>
      </template>
    </vc-calendar>

    <!-- MODALS -->
    <ConfirmDeleteEventModal :show="showConfirmDeleteModal" :loading="isDeleting" @on-confirm="onDelete" @on-cancel="onCloseDeleteConfirmationModal"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import ConfirmDeleteEventModal from '@/core/components/common/modals/ConfirmDeleteEventModal.vue';
import { GENERIC_ERROR_MESSAGE } from '@/core/constants/messages';
import { USER_CALENDAR_STORE } from '@/store/modules/user-calendar';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    ConfirmDeleteEventModal,
  },
  props: ['attributes'],
  emits: ['set-active-object', 'reload-schedule', 'open-edit-modal'],
  data() {
    return {
      activeKey: null,
      masks: {
        weekdays: 'WWW',
      },
      isDeleting: false,
      showConfirmDeleteModal: false,
      selectedUserCalendarId: null,
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
  },

  methods: {
    ...mapActions(USER_CALENDAR_STORE, ['deleteSchedule']),

    setActiveObject(attri) {
      this.activeKey = attri.key;
      this.$emit('set-active-object', attri);
    },

    openConfirmDeleteModal(id) {
      this.selectedUserCalendarId = id;
      this.showConfirmDeleteModal = true;
    },

    onCloseDeleteConfirmationModal() {
      this.showConfirmDeleteModal = false;
      this.selectedUserCalendarId = null;
    },

    openEditModal(schedule) {
      this.$emit('open-edit-modal', schedule);
    },

    async onDelete() {
      const userId = this.user.id;
      const params = {
        userId,
        userCalendarId: this.selectedUserCalendarId,
      };

      this.isDeleting = true;

      await this.deleteSchedule(params)
        .then(async (response) => {
          if (response) {
            this.$notify.success({
              title: 'Delete successful.',
              message: 'Event successfully deleted.',
            });
            this.$emit('reload-schedule');
          }
        })
        .catch(() => {
          this.$notify.error({
            title: 'Event Deletion Failed',
            message: GENERIC_ERROR_MESSAGE,
          });
        })
        .finally(() => {
          this.isDeleting = false;
          this.onCloseDeleteConfirmationModal();
        });
    }
  },
});
</script>

<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
:deep(.custom-calendar.vc-container) {
  --day-border: 2px solid rgba(12, 15, 74, 0.1);
  --day-border-highlight: 2px solid rgba(12, 15, 74, 0.1);
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #FFF;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #F1F6FB;
    padding: 10px;
  }
  & .vc-title {
        font-family: Mulish;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0.25px;
        text-align: left;
        color: #0C0F4A;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 6px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: #F1F6FB;
    overflow: auto;
    // &.weekday-1,
    // &.weekday-7 {
    //   background-color: #eff8ff;
    // }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
  & .vc-arrows-container {
    background: white;
    width: auto;
    box-shadow: 0px 0px 20px rgb(42 42 42 / 10%);
    border-radius: 8px;
    height: 30px;
    top: 7px;
    left: 180px;
    width: 80px;
    box-sizing: border-box;
    justify-content: space-between;
  }
  & .vc-arrow {
      &:hover {
        background-color: #FFF;
      }
      &:focus {
        background-color: #FFF;
      }
  }
  & .vc-day-box-center-center {
    justify-content: flex-start;
    align-items: flex-start
  }
  & .vc-highlight {
    width: 20px;
    height: 20px;
    top: 2px;
    position: relative;
  }
}
.event-info-container {
    background: #FFF;
    width: 100%;
    box-shadow: 0px 0px 20px rgba(42, 42, 42, 0.1);
    border-radius: 4px;
    padding: 5px 5px 5px 12px;
    box-sizing: border-box;
    margin-top: 0.4rem;
    cursor: pointer;
}
.event-info-container.active {
    border: 1px solid #FFA500;
}
.event-title {
    font-size: 8px;
    font-weight: 700;
    line-height: 10px;
    letter-spacing: 0.25px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      background-color: #FFA500;
      left: -7px;
      top: 2px;
      border-radius: 100%;
    }
}
.spots {
    font-size: 8px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: rgba(12, 15, 74, 0.5);
}
.day-label {
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
}

.el-popover {
  width: auto;
}
.pop-over {
  &__option {
    cursor: pointer;
    padding: .3rem;
    border-radius: 4px;
    &:hover {
        background: rgba(255, 165, 0, 0.1);
        .options__label {
            color: #FFA500;
        }
    }

    &:last-child {
        position: relative;
        margin-top: 1rem;
        &::before {
            content: '';
            background: rgba(12, 15, 74, 0.2);
            position: absolute;
            top: -1rem;
            left:0;
            right:0;
            width: 100%;
            height: 1px;
        }
    }
  }

  &__label {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: #0C0F4A;
    margin: 0;
    // white-space: nowrap;
  }
}

@include media-md-max-width() {
  .text-center {
    padding: 0 25px;
  }
}
@include media-sm-max-width() {
  :deep(.custom-calendar.vc-container) {
    & .vc-day {
      height: 70px !important;
      min-width: 40px !important;
    }
  }
}
</style>
